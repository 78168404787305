import cn from 'classnames'

import { CurrencyIconWrapper } from './CurrencyIcon.styled'

interface CurrencyIconProps {
  currency: string
  width?: string
  height?: string
  className?: string
}

export const CurrencyIcon = ({ currency, width = '3rem', height = '3rem', className }: CurrencyIconProps) => (
  <CurrencyIconWrapper
    width={width}
    height={height}
    className={cn(className, {
      [currency?.replace(/\./g, '-')]: true
    })}
  />
)
