import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { toast } from 'react-hot-toast'

import { Button, DragAndDrop, ErrorDisplay, FileInput } from 'mmfintech-portal-commons'
import { NewInvoiceLeft, NewInvoiceRight, NewInvoiceWrapper } from './NewInvoiceModal.styled'

import settings from '../../../settings'
import { toastOptions } from '../../../components'
import { checkFileUpload, isValidArray, tr, translateError } from 'mmfintech-commons'
import {
  paths,
  useGetEnotasMerchantMetadataQuery,
  useGetMerchantQuery,
  useUploadInvoiceMutation
} from 'mmfintech-backend-api'

import { MerchantEntityTypeEnum } from 'mmfintech-commons-types'

import UploadInvoiceImage from '../../../images/speedy-upload-invoice.png'

import Icon from '../../../images/icons/deposit.svg?react'

type NewInvoiceModalProps = {
  onClose?: () => void
  onNewNotaFiscal?: () => void
}

export const NewInvoiceModal = ({ onClose, onNewNotaFiscal }: NewInvoiceModalProps) => {
  const [fileError, setFileError] = useState(null)

  const history = useHistory()

  const { data: merchant } = useGetMerchantQuery()
  const { error: metadataError } = useGetEnotasMerchantMetadataQuery()

  const [uploadInvoice, { error: uploadInvoiceError, isLoading: uploadInvoiceFetching }] = useUploadInvoiceMutation({
    fixedCacheKey: 'upload-invoice-mutation'
  })

  const handleClose = () => {
    typeof onClose === 'function' && onClose()
  }

  const handleSpeedyPayClick = () => {
    handleClose()
    history.push(paths.invoices.create(), { origin: 'dashboard' })
  }

  const handleNewNotaFiscalClick = () => {
    handleClose()
    if (metadataError) {
      toast.remove()
      toast.error(translateError(metadataError), toastOptions.error)
    } else {
      typeof onNewNotaFiscal === 'function' && onNewNotaFiscal()
    }
  }

  const handleFileError = (errors: any) => setFileError(checkFileUpload(errors))

  const handleFileSelected = async (files: any) => {
    setFileError(null)
    if (isValidArray(files)) {
      try {
        await uploadInvoice(files[0]).unwrap()
        handleClose()
        history.push(paths.invoices.upload())
      } catch (_error) {}
    }
  }

  return (
    <NewInvoiceWrapper data-test='invoice-new-modal'>
      <NewInvoiceLeft className='left-side'>
        <div className='header'>
          <div className='title'>{tr('FRONTEND.INVOICE.NEW.TITLE', 'New Invoice')}</div>
          <div className='subtitle'>
            {tr('FRONTEND.INVOICE.NEW.SUBTITLE', 'Create a new invoice or just drop here your invoice file.')}
          </div>
          <ErrorDisplay error={[fileError, uploadInvoiceError]} />
        </div>
        <div className='buttons'>
          <Button
            color='round-primary'
            icon={<Icon className='mr-1' width={26} height={26} />}
            text={tr('FRONTEND.DASHBOARD.SPEEDY_PAY', 'SpeedyPay')}
            disabled={uploadInvoiceFetching}
            onClick={handleSpeedyPayClick}
            data-test='button-speedy-pay'
          />
          {merchant?.countryCode === 'BRA' && merchant?.entityType == MerchantEntityTypeEnum.COMPANY && (
            <Button
              color='round-primary'
              icon={<Icon className='mr-1' width={26} height={26} />}
              text={tr('FRONTEND.DASHBOARD.BUTTON_NEW_NF', 'New Nota Fiscal')}
              disabled={uploadInvoiceFetching}
              onClick={handleNewNotaFiscalClick}
              data-test='button-nota-fiscal'
            />
          )}
        </div>
      </NewInvoiceLeft>

      <NewInvoiceRight className='right-side'>
        <img src={UploadInvoiceImage} alt='' />
        <DragAndDrop
          onDrop={handleFileSelected}
          onError={handleFileError}
          maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
          acceptType={settings.defaultUploadInvoiceAcceptType}>
          <FileInput
            maxNumber={1}
            maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
            acceptType={settings.defaultUploadInvoiceAcceptType}
            onChange={handleFileSelected}
            onError={handleFileError}>
            {({ onFileUpload, onRemoveAll }) => (
              <>
                <div
                  className='upload-box'
                  data-test='upload-box'
                  onClick={() => {
                    onRemoveAll()
                    onFileUpload()
                  }}>
                  {tr('FRONTEND.INVOICE.NEW.DROP_HERE', 'drop invoice here')}
                  <div className='file-types'>{settings.defaultUploadInvoiceAcceptType.join(', ')}</div>
                </div>
              </>
            )}
          </FileInput>
        </DragAndDrop>
      </NewInvoiceRight>
    </NewInvoiceWrapper>
  )
}
