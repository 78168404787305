import { useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { ButtonWrapper } from './NewInvoiceButton.styled'
import { NewInvoiceModal } from '../NewInvoiceModal'
import { GlobalContext, isNotEmptyString } from 'mmfintech-commons'

import { useEnotas } from '../../../hooks'
import {
  isMerchantProspect,
  paths,
  useGetEnotasMerchantMetadataQuery,
  useGetMerchantQuery
} from 'mmfintech-backend-api'

import NewInvoiceIcon from '../../../images/icons/invoice-new.svg?react'

type NewInvoiceButtonProps = {
  origin?: string
  payerId?: string | number
}

export const NewInvoiceButton = ({ origin, payerId }: NewInvoiceButtonProps) => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const { data: merchant } = useGetMerchantQuery()
  const { data: metadata, isFetching: metadataFetching } = useGetEnotasMerchantMetadataQuery()

  const history = useHistory()
  const enotas = useEnotas()

  const enotasIssueInvoice = () => {
    const state = isNotEmptyString(origin) ? { origin, payerId } : null
    if ((payerId as number) > 0) {
      history.push(paths.enotas.invoice.createForCustomer(payerId), state)
    } else {
      history.push(paths.enotas.invoice.create(), state)
    }
  }

  const enotasCheckCompanyUpdate = () => {
    const { isFirstInvoice } = metadata || {}

    if (isFirstInvoice) {
      enotas.updateCompany(enotasIssueInvoice)
    } else {
      enotasIssueInvoice()
    }
  }

  const handleNewNotaFiscalClick = () => {
    if (!metadataFetching) {
      const { citySupported, certificateUploaded, certificateRequired, merchantHasCompany } = metadata || {}

      if (isMerchantProspect(merchant)) {
        enotas.notOnboarded()
      } else if (citySupported) {
        if (merchantHasCompany) {
          if (certificateRequired && !certificateUploaded) {
            enotas.uploadCertificate(enotasCheckCompanyUpdate)
          } else {
            enotasCheckCompanyUpdate()
          }
        } else if (certificateRequired) {
          if (!certificateUploaded) {
            enotas.uploadCertificate(enotasCheckCompanyUpdate)
          } else {
            enotasCheckCompanyUpdate()
          }
        } else {
          enotas.updateCredentials(enotasCheckCompanyUpdate)
        }
      } else {
        enotas.showCityNotSupportedScreen()
      }
    }
  }

  const handleNewInvoiceClick = () => {
    modalShow({
      options: { maxWidth: 940, size: 'medium' },
      content: <NewInvoiceModal onNewNotaFiscal={handleNewNotaFiscalClick} onClose={modalHide} />
    })
  }

  return (
    <ButtonWrapper data-test='new-invoice-button' onClick={handleNewInvoiceClick}>
      <NewInvoiceIcon />
    </ButtonWrapper>
  )
}
